import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/session',
    name: 'session',
    component: () => import(/* webpackChunkName: "Session" */ '../views/Session.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "User" */ '../views/User.vue')
  },
  {
    path: '/addressBook',
    name: 'addressBook',
    component: () => import(/* webpackChunkName: "AddressBook" */ '../views/AddressBook.vue')
  },
  {
    path: '/initiateGroupChat',
    name: 'initiateGroupChat',
    component: () => import(/* webpackChunkName: "initiateGroupChat" */ '../views/InitiateGroupChat.vue')
  },
  {
    path: '/InitiateGroupChatJIARU',
    name: 'InitiateGroupChatJIARU',
    component: () => import(/* webpackChunkName: "InitiateGroupChatJIARU" */ '../views/InitiateGroupChatJIARU.vue')
  },
  {
    path: '/InitiateGroupChatSHANCHU',
    name: 'InitiateGroupChatSHANCHU',
    component: () => import(/* webpackChunkName: "InitiateGroupChatSHANCHU" */ '../views/InitiateGroupChatSHANCHU.vue')
  },
  {
    path: '/InitiateGroupChatGuanLiYuan',
    name: 'InitiateGroupChatGuanLiYuan',
    component: () => import(/* webpackChunkName: "InitiateGroupChatGuanLiYuan" */ '../views/InitiateGroupChatGuanLiYuan.vue')
  },
  {
    path: '/InitiateGroupChatJinYan',
    name: 'InitiateGroupChatJinYan',
    component: () => import(/* webpackChunkName: "InitiateGroupChatJinYan" */ '../views/InitiateGroupChatJinYan.vue')
  },
  {
    path: '/SingleChat',
    name: 'SingleChat',
    component: () => import(/* webpackChunkName: "SingleChat" */ '../views/SingleChat.vue')
  },
  {
    path: '/groupChat',
    name: 'groupChat',
    component: () => import(/* webpackChunkName: "groupChat" */ '../views/groupChat.vue')
  },
  {
    path: '/managementGroup',
    name: 'managementGroup',
    component: () => import(/* webpackChunkName: "managementGroup" */ '../views/managementGroup.vue')
  },
  {
    path: '/AllGruopMenber',
    name: 'AllGruopMenber',
    component: () => import(/* webpackChunkName: "AllGruopMenber" */ '../views/AllGruopMenber.vue')
  },
  {
    path: '/GroupManage',
    name: 'GroupManage',
    component: () => import(/* webpackChunkName: "GroupManage" */ '../views/GroupManage.vue')
  },
  {
    path: '/ProhibitionTime',
    name: 'ProhibitionTime',
    component: () => import(/* webpackChunkName: "ProhibitionTime" */ '../views/ProhibitionTime.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
