<template>
  <div class="home">
    <!-- 蒙版 -->
    <div class="modeHeadmegnban" v-if="modeHeadmegnbanstatus" @click="handleClickOutside"></div>
    <!-- 头部导航栏 -->
    <div class="topHead">
      <!-- <img class="backHead" src="../assets/back.png" alt=""> -->
      <div class="backHead"></div>
      <div class="titleText">聊天室</div>
      <!-- <img @click.stop="getmodeHeadStatus()" class="jiaHead" src="../assets/jia.png" alt=""> -->
      <div class="backHead"></div>

      <!-- 右上角添加好友/发起群聊弹窗 -->
      <div class="modeHead" v-if="modeHeadStatus">
        <div class="modeItem border" @click.stop="getInitiateGroupChat()">
          <img src="../assets/qunliao.png" alt="">
          <div>发起群聊</div>
        </div>
        <div class="modeItems" @click.stop="gethaiyouModeStatus()">
          <img src="../assets/tianjia.png" alt="">
          <div>添加好友</div>
        </div>
        <div class="modeItems" @click.stop="getdedlete()">
          <img src="../assets/tiren.png" alt="">
          <div>清除所有会话</div>
        </div>
      </div>
    </div>
    <!-- 消息列表 -->
    <div class="itemList" @click.stop="getSingleChat(item)" v-for="(item, index) in ConversationList" :key="index">
      <div v-for="(it, ind) in userInfoList" :key="ind" v-if="item.conversationType == 1 && it.id == item.targetId">
        <img :src="it.avatar ? it.avatar : '../assets/groupLogo.png'" class="itemImg" alt="">
      </div>
      <img v-if="item.conversationType == 3" src=".././assets/groupLogo.png" class="itemImg" alt="">

      <div class="itemInfo">
        <div class="itemInfoTop">
          <div class="itemInfoTopLeft">
            <div v-for="(it, ind) in userInfoList" :key="ind">
              <div class="itemInfoTitle" v-if="item.conversationType == 1 && it.id == item.targetId">{{
                it.mark ? it.mark : it.account }}</div>
              <div class="itemInfoTitle" v-if="item.conversationType == 3 && it.group_id == item.targetId">{{
                it.group_name
              }}</div>
            </div>
            <div class="itemInfoMsg" v-if="item.conversationType == 3">交易热聊群</div>
          </div>
          <!-- <div class="itemInfoTopLeft">
            {{ formatTimestamp(item.lastUnreadTime) }}
          </div> -->
        </div>
        <div class="itemInfoBottom">
          <div class="itemInfoContent">{{ item.latestMessage.content.remoteUrl ? '语音' :
            item.latestMessage.content.imageUri ? '图片' : item.latestMessage.content.sightUrl ? '视频' :item.latestMessage.content.content }}</div>
          <!-- <div class="itemInfoMsg">{{ item.unreadMessageCount }}</div> -->
          <div class="itemInfoMsgs" v-if="jiantinghuihua == index">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="itemList" @click="getGroupChat()">
      <img src="../assets/logo.png" class="itemImg" alt="">
      <div class="itemInfo">
        <div class="itemInfoTop">
          <div class="itemInfoTopLeft">
            <div class="itemInfoTitle">CCV官方群-01</div>
            <div class="itemInfoMsg">官方</div>
          </div>
          <div class="itemInfoTopLeft">
            18:08
          </div>
        </div>
        <div class="itemInfoBottom">
          <div class="itemInfoContent">154***@qq.com:怎么注销@CCV官...</div>
          <div class="itemInfoMsg">99+</div>
        </div>
      </div>
    </div> -->
    <!-- 底部tabbar -->
    <!-- <div class="tabbarHead">
      <div class="tabbarLeft" @click.stop="getTabar(1)">
        <img src="../assets/tab1s.png" alt="">
        <div class="tabbarTits">会话</div>
      </div>
      <div class="tabbarCenter" @click.stop="getTabar(2)">
        <img src="../assets/tab2.png" alt="">
        <div class="tabbarTit">通讯录</div>
      </div>
      <div class="tabbarRight" @click.stop="getTabar(3)">
        <img src="../assets/tab3.png" alt="">
        <div class="tabbarTit">自己</div>
      </div>
    </div> -->
    <!-- 添加好友弹窗 -->
    <div class="haiyouMode" v-if="haiyouModeStatus">
      <div class="haiyouModes">
        <div class="haiyouTit">添加好友</div>
        <div class="haiyouInput">
          <input type="text" v-model="name" placeholder="请输入用户名">
        </div>
        <div class="haiyouBUT">
          <div class="haiyouLeft" @click.stop="getadd()">确定</div>
          <div class="haiyouRight" @click.stop="gethaiyouModeStatus()">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <script src="https://cdn.ronghub.com/RongIMLib-5.9.5.prod.js"></script> -->
<script>
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
// CMD
const RongIMLib = require('@rongcloud/imlib-next')
import VConsole from 'vconsole';

// ES
// import * as RongIMLib from '@rongcloud/imlib-next'

// 假设api.js位于项目的根目录下，并且已配置了@符号指向src
export default {
  name: 'Session',
  components: {
  },
  data() {
    return {
      modeHeadmegnbanstatus: false,
      modeHeadStatus: false,
      haiyouModeStatus: false,
      ConversationList: [],
      name: '',
      userInfoList: [],
      jiantinghuihua: -1
    }
  },
  created() {
    // 清除本地全部缓存数据
    // window.localStorage.clear();
    // 获取当前路由的query参数对象
    const query = this.$route.query;

    // 检查并提取token值
    if (query.token) {
      const token = query.token;
      // 将token保存到Vuex store、本地存储或其他数据管理方案
      localStorage.setItem('token', token);
      console.log('Token:', token); // 输出token值
    }
    // 本地测试需要写死一个token
    // localStorage.setItem('token', '325656ba19b4e0adc423cd1e4e17bb8e');
    // 新建一个 vconsole 实例
    const vconsole = new VConsole();

    // 如果只在开发环境下启用 vconsole，可以添加条件判断
    if (process.env.NODE_ENV !== 'production') {
      vconsole.init();
    }
    // 应用初始化，请务必保证此过程只被执行一次
    this.ConversationList = [];
    this.userInfoList = [];
    // this.getData();
    this.$http.post('/app/index.php?i=6&c=entry&m=ewei_shopv2&do=mobile&r=chatapi.user', {
      token: localStorage.getItem('token')
    })
      .then(response => {
        this.info = response.data.result;
        localStorage.setItem('chat_token', this.info.chat_token);
        // 链接服务器  发送链接请求
        RongIMLib.connect(localStorage.getItem('chat_token')).then((res) => {
          if (res.code === 0) {
            console.log('当前链接服务器用户id:', res.data.userId)
          }
        })
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
    // 应用初始化，请务必保证此过程只被执行一次
    RongIMLib.init({ appkey: '8luwapkv8hevl' });
    // 添加事件监听
    const Events = RongIMLib.Events
    // chat_token：用户信息接口返回token

    const listener = (evt) => console.log(evt.messages)

    // 添加具体事件监听器
    RongIMLib.addEventListener(Events.MESSAGES, listener)

    // 移除具体事件监听器
    // RongIMLib.removeEventListener(Events.MESSAGES, listener)

    // 移除具体事件的所有监听器
    // RongIMLib.removeEventListeners(Events.MESSAGES)


    /**
     * 正在链接的事件状态
     */
    RongIMLib.addEventListener(Events.CONNECTING, () => {
      console.log('正在链接...')
    })

    /**
     * 链接到服务器会触发这个事件
     */
    RongIMLib.addEventListener(Events.CONNECTED, () => {
      console.log('连接成功')
    })

    /**
     * 手动调用 disconnect 方法或者用户被踢下线 会触发这个事件
     */
    RongIMLib.addEventListener(Events.DISCONNECT, (code) => {
      console.log('连接中断，需要业务层进行重连处理 ->', code)
    })

    /**
     * 链接出问题时，内部进行重新链接，会触发这个事件
     */
    RongIMLib.addEventListener(Events.SUSPEND, (code) => {
      console.log('链接中断，SDK 会尝试重连，业务层无需关心')
      // 5.1.2 版本开始，事件回调中会引起中断的 code 状态码
      console.log(`code -> ${code}`)
    })


    RongIMLib.addEventListener(Events.CONNECTING, () => {
      console.log('正在链接服务器')
    })

    RongIMLib.addEventListener(Events.CONNECTED, () => {
      console.log('已经链接到服务器')
      // 删除会话
      // const conversationType = RongIMLib.ConversationType.GROUP;
      // const targetId = '65ee714d6fcfc';

      // RongIMLib.removeConversation({
      //   conversationType,
      //   targetId: targetId,
      // }).then(res => {
      //   // 删除指定会话成功
      //   if (res.code === 0) {
      //     console.log('删除指定会话成功',res)
      //   } else {
      //     console.log('删除指定会话失败',res.code, res.msg)
      //   }
      // })
      // 链接到服务器后获取会话列表
      this.getgetConversationList();
      // setInterval(() => {
      //   // this.$router.push({
      //   //   name: 'home'
      //   // })
      //   this.ConversationList = [];
      //   this.userInfoList = [];
      //   console.log(  this.ConversationList, this.userInfoList ,'清空')
      //   this.getgetConversationList();
      // }, 5000)
    })

    RongIMLib.addEventListener(Events.MESSAGES, (evt) => {
      console.log(evt.messages)
    })
  },
  beforeDestroy() {
    // 清除本地全部缓存数据
    // window.localStorage.clear();
    console.log('销毁前触发')
    // 移除具体事件监听器
    const Events = RongIMLib.Events
    const listener = (evt) => console.log(evt.messages)
    RongIMLib.removeEventListener(Events.CONVERSATION, listener)// 组件销毁前触发
  },
  mounted() {
    this.getgetConversationList();
    console.log('this.ConversationList', this.ConversationList)

    // // if (this.ConversationList.length == 0) {
    // //   this.ConversationList = [];
    // //   this.userInfoList = [];
    // this.getgetConversationList();
    // // return;
    // // }
    // // 监听新的会话信息
    // const Events = RongIMLib.Events
    // RongIMLib.addEventListener(Events.CONVERSATION, (evt) => {
    //   console.log('evt.messages:', evt.conversationList[0].conversation)
    //   var conversation = evt.conversationList[0].conversation;
    //   if (!this.ConversationList.some(item => item.targetId === conversation.targetId)) {
    //     console.log(item.targetId, conversation.targetId, '对比是否已存在该会话')
    //     var arr = [];
    //     arr.push(conversation.targetId)
    //     this.$http.post('/app/index.php?i=6&c=entry&m=ewei_shopv2&do=mobile&r=chatapi.groupInfo', {
    //       token: localStorage.getItem('token'), groupId: arr
    //     })
    //       .then(response => {
    //         this.ConversationList.push(conversation)
    //         this.userInfoList.push(response.data.result[0]);
    //       })
    //       .catch(error => {
    //         console.error("Error fetching data:", error);
    //       });
    //   } else {
    //     for (var i = 0; i < this.ConversationList.length; i++) {
    //       // 如果已有当前会话
    //       if (this.ConversationList[i].targetId == conversation.targetId) {
    //         this.ConversationList.splice(i, 1, conversation);
    //         console.log(this.ConversationList[i], '替换后的数据');
    //         return;
    //       }
    //     }
    //   }
    //   return;

    // })
  },
  methods: {
    // 获取新的会话列表
    getCONVERSATION() {
      // 获取新的会话信息
      const Events = RongIMLib.Events;
      RongIMLib.addEventListener(Events.CONVERSATION, (evt) => {
        console.log('evt.messages:', evt.conversationList[0].conversation);
        var conversation = evt.conversationList[0].conversation;

        // 在.some()的回调函数内部使用item，并确保其在对比时不被外部引用
        let isConversationExist = this.ConversationList.some((item) => {
          console.log(item.targetId, conversation.targetId, '对比是否已存在该会话')
          return item.targetId === conversation.targetId;
        });
        console.log(isConversationExist, 'isConversationExist')
        if (!isConversationExist) {
          var arr = [];
          arr.push(conversation.targetId);

          this.$http.post('/app/index.php?i=6&c=entry&m=ewei_shopv2&do=mobile&r=chatapi.groupInfo', {
            token: localStorage.getItem('token'),
            groupId: arr,
          })
            .then((response) => {
              this.ConversationList.push(conversation);
              this.userInfoList.push(response.data.result[0]);
              this.jiantinghuihua = this.ConversationList.length - 1;
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          for (var i = 0; i < this.ConversationList.length; i++) {
            if (this.ConversationList[i].targetId === conversation.targetId) {
              this.ConversationList.splice(i, 1, conversation);
              console.log(this.ConversationList[i], '替换后的数据');
              this.jiantinghuihua = i;
              return;
            }
          }
        }
      });
    },
    //关闭所有弹窗
    handleClickOutside() {
      this.modeHeadStatus = false;
      this.haiyouModeStatus = false;
      this.modeHeadmegnbanstatus = false;
    },
    // 清除所有会话
    getdedlete() {
      for (var i = 0; i < this.ConversationList.length; i++) {
        if (this.ConversationList[i].conversationType == 1) {
          // 删除会话
          const conversationType = RongIMLib.ConversationType.PRIVATE;
          const targetId = this.ConversationList[i].targetId;

          RongIMLib.removeConversation({
            conversationType,
            targetId: targetId,
          }).then(res => {
            // 删除指定会话成功
            if (res.code === 0) {
              console.log('删除指定会话成功', res)
            } else {
              console.log('删除指定会话失败', res.code, res.msg)
            }
          })
        } else {
          // 删除会话
          const conversationType = RongIMLib.ConversationType.GROUP;
          const targetId = this.ConversationList[i].targetId;
          RongIMLib.removeConversation({
            conversationType,
            targetId: targetId,
          }).then(res => {
            // 删除指定会话成功
            if (res.code === 0) {
              console.log('删除指定会话成功', res)
            } else {
              console.log('删除指定会话失败', res.code, res.msg)
            }
          })
        }
      }
      this.ConversationList = [];
      // 链接到服务器后获取会话列表
      this.getgetConversationList();
    },
    // 时间戳格式化
    formatTimestamp(timestamp) {
      var date = new Date(timestamp * 1000);
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = ("0" + date.getHours()).slice(-2);
      var minute = ("0" + date.getMinutes()).slice(-2);
      var second = ("0" + date.getSeconds()).slice(-2);
      return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    },
    // 获取chatToken
    getData() {
      this.$http.post('/app/index.php?i=6&c=entry&m=ewei_shopv2&do=mobile&r=chatapi.user', {
        token: localStorage.getItem('token')
      })
        .then(response => {
          this.info = response.data.result;
          localStorage.setItem('chat_token', this.info.chat_token);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    },
    // 添加好友
    getadd() {
      if (this.name == '') {
        Toast('请输入用户名');
        return;
      }
      this.$http.post('/app/index.php?i=6&c=entry&m=ewei_shopv2&do=mobile&r=chatapi.addFriend', {
        token: localStorage.getItem('token'), name: this.name
      })
        .then(response => {
          this.name = '';
          this.haiyouModeStatus = false;
          Toast(response.data.result.message);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    },
    // 获取会话列表
    getgetConversationList() {
      var that = this;
      that.ConversationList = [];
      that.userInfoList = [];
      const startTime = 0;
      const count = 100;
      const order = 0;
      RongIMLib.getConversationList({
        count: count,
        startTime: startTime,
        order: order
      }).then(res => {
        if (res.code === 0) {
          console.log('会话列表数组长度', res.data.length)

          console.log('会话列表', res.data)

          for (var i = 0; i < res.data.length; i++) {
            // return;
            if (res.data[i].conversationType == 1) {//个人信息
              var arr = [];
              arr.push(res.data[i].targetId)
              this.$http.post('/app/index.php?i=6&c=entry&m=ewei_shopv2&do=mobile&r=chatapi.userList', {
                token: localStorage.getItem('token'), userId: arr
              })
                .then(response => {
                  that.userInfoList.splice(i, 0, response.data.result[0]);
                })
                .catch(error => {
                  console.error("Error fetching data:", error);
                });
            } else {
              var arr = [];
              arr.push(res.data[i].targetId)
              this.$http.post('/app/index.php?i=6&c=entry&m=ewei_shopv2&do=mobile&r=chatapi.groupInfo', {
                token: localStorage.getItem('token'), groupId: arr
              })
                .then(response => {
                  console.log(response, 'responseresponseresponseresponseresponse')
                  that.userInfoList = [];
                  that.userInfoList.splice(i, 0, response.data.result[0]);
                })
                .catch(error => {
                  console.error("Error fetching data:", error);
                });
            }
          }
          that.ConversationList = [];
          that.ConversationList = res.data;
          that.getCONVERSATION();
          // 每五秒请求一次会话列表
          console.log(that.userInfoList, '会话列表信息数据')


          // that.getdedlete();//清除所有会话
        } else {
          console.log(res.code, res.msg)
        }
      })
    },

    // 跳转到群聊页面
    getGroupChat() {
      this.$router.push({
        name: 'groupChat'
      })
    },
    // 跳转到单聊页面
    getSingleChat(item) {
      if (item.conversationType == 1) {
        for (var i = 0; i < this.userInfoList.length; i++) {
          if (this.userInfoList[i].id == item.targetId) {
            this.$router.push({
              name: 'SingleChat',
              params: { frendInfo: this.userInfoList[i], type: 'home' }
            })
          }
        }
      } else {
        for (var i = 0; i < this.userInfoList.length; i++) {
          if (this.userInfoList[i].group_id == item.targetId) {
            localStorage.setItem('frendInfo', JSON.stringify(this.userInfoList[i]))
            this.$router.push({
              name: 'groupChat',
              params: { frendInfo: this.userInfoList[i] }
            })
          }
        }
      }

    },
    // 跳转到发起群聊页面
    getInitiateGroupChat() {
      this.$router.push({
        name: 'initiateGroupChat'
      })
    },
    // 控制添加好友弹窗
    gethaiyouModeStatus() {
      this.haiyouModeStatus = !this.haiyouModeStatus;
    },
    // 右上角+弹窗控制
    getmodeHeadStatus() {
      this.modeHeadStatus = !this.modeHeadStatus;
      this.modeHeadmegnbanstatus = true;
    },

    // 底部tabbar跳转
    getTabar(type) {
      if (type == 1) {
        this.$router.push({
          name: 'home'
        })
      } else if (type == 2) {
        this.$router.push({
          name: 'addressBook'
        })
      } else {
        this.$router.push({
          name: 'user'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.itemInfoMsgs {
  color: #111111;
  padding: .20rem .20rem;
  border-radius: .25rem;
  background-color: #BCFF33;
  font-size: .75rem;
  margin-left: 1.125rem;
}

.modeHeadmegnban {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.haiyouRight {
  width: 4.125rem;
  height: 1.625rem;
  border-radius: .3125rem;
  background-color: #616161;
  color: #fff;
  font-size: .9375rem;
  text-align: center;
  line-height: 1.625rem;
}

.haiyouLeft {
  width: 4.125rem;
  height: 1.625rem;
  border-radius: .3125rem;
  background-color: #BCFF33;
  color: #111111;
  font-size: .9375rem;
  text-align: center;
  line-height: 1.625rem;
}

.haiyouBUT {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2.3125rem;
}

.haiyouInput input {
  font-size: .9375rem;
  color: #C2C2C2;
  background-color: transparent;
  /* 设置背景色为透明 */
  border: none;
  /* 移除所有边框 */
  padding-left: 1.4375rem;

}

.haiyouInput {
  width: 18.5rem;
  margin: auto;
  margin-top: 2rem;
  padding-bottom: .6875rem;
  border-bottom: .0625rem solid #B1B1B1;
}

.haiyouTit {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  border-width: 600;
  margin-top: 1.0625rem;
  font-weight: 600;
}

.haiyouModes {
  width: 20.5rem;
  height: 12rem;
  background-color: #242424;
  border-radius: .625rem;
}

.haiyouMode {
  position: fixed;
  /* 使用固定定位使其脱离文档流并相对于视口定位 */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* 覆盖整个屏幕 */
  display: flex;
  /* 使用Flex布局来辅助居中 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  z-index: 999;
  /* 确保弹窗在所有元素之上 */
}

.modeItems div {
  width: 60%;
  height: 100%;
  font-size: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
}

.modeItems img {
  width: 1.3125rem;
  height: 1.3125rem;
  margin-left: 1.3125rem;
}

.modeItems {
  width: 100%;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modeItem div {
  width: 60%;
  height: 100%;
  border-bottom: .0625rem solid #616161;
  font-size: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
}

.modeItem img {
  width: 1.5rem;
  height: 1.1875rem;
  margin-left: 1.25rem;
}

.modeItem {
  width: 100%;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modeHead {
  width: 10rem;
  height: 6.875rem;
  position: absolute;
  top: 2.5rem;
  right: .625rem;
  background-image: url('../assets/mode.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.tabbarRight img {
  width: 1.1875rem;
  height: 1.375rem;
}

.tabbarCenter img {
  width: 1.5rem;
  height: 1.3125rem;
}

.tabbarRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 2.1875rem;
}

.tabbarCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabbarTits {
  color: #BCFF33;
  font-size: .75rem;
  margin-top: .25rem;
}

.tabbarTit {
  color: #9d9d9d;
  font-size: .75rem;
  margin-top: .25rem;
}

.tabbarLeft img {
  width: 1.3125rem;
  height: 1.3125rem;
}

.tabbarLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 2.1875rem;
}

.tabbarHead {
  width: 100%;
  height: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #242424;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999999999;
}

.itemInfoContent {
  font-size: .8125rem;
  color: #9C9D9F;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  /*不允许换行*/
  text-overflow: ellipsis;
  /*超出部分省略号显示*/
}

.itemInfoBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .4375rem;
}

.itemInfoTopLeft {
  font-size: .75rem;
  color: #9C9D9F;
}

.itemInfoMsg {
  color: #111111;
  padding: .10rem .20rem;
  border-radius: .25rem;
  background-color: #BCFF33;
  font-size: .75rem;
  margin-left: 1.125rem;
}

.itemInfoTitle {
  font-size: .875rem;
  color: #fff;
  font-weight: 600;
}

.itemInfoTopLeft {
  display: flex;
  align-items: center;
}

.itemInfoTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemInfo {
  width: 80%;
  margin-left: .5rem;
  border-bottom: .0625rem solid #242424;
  padding-bottom: .8125rem;
}

.itemImg {
  width: 2.6875rem;
  height: 2.6875rem;
  border-radius: .9375rem;
}

.itemList {
  width: 95%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.25rem;
}

.jiaHead {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.125rem;
}

.titleText {
  font-size: 1.0625rem;
  color: #FFFFFF;
  font-weight: 600;
}

.backHead {
  width: .5625rem;
  height: .9375rem;
  margin-left: 1.125rem;
}

.topHead {
  width: 100%;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.04rem solid #242424;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  background: #000;
}

.home {
  padding-top: 3.125rem;
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
}
</style>
